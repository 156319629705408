import React, { MouseEvent, CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'


interface CircleProps {
	className?: string,
	style?: CSSProperties,
	children?: ReactNode, 
	type?: 'submit' | 'reset' | 'button',
	onClick?: (event: MouseEvent) => void,
	icon?: ReactNode
}
export default ({
	className,
	style,
	children,
	type,
	onClick,
	icon
}: CircleProps) => {
	return (
		<Button
			className={className}
			style={style}
			type={type}
			onClick={onClick}
		>
			{icon ? icon : children}
		</Button>
	)
}

const Button = styled.button`
	border: none;
	margin: 0;
	padding: 0;
	background: transparent;
	cursor: pointer;

	background: ${ ({theme}) => theme.colors.grayLight};

	width: 3.125vw;
	height: 3.125vw;
	border-radius: 50%;

	&:active { 
		outline: none;
	}
`