import React, { 
  useContext 
} from 'react';

import Layout, { 
  MyLocationEnum 
} from '../components/Layout'

import { 
  useStaticQuery, 
  graphql 
} from 'gatsby';

import SEO, { 
  MetaOG
} from "../components/Seo"

import Program from '../components/Program/components/Program';
import DynamicContext from '../context'

export default ({ location }) => {

  const { liveSpeaker, speakerLinks } = useContext(DynamicContext)

  const data = useStaticQuery(
    graphql`
      query {
        allSpeaker (sort: {fields: time, order: ASC}) {
          nodes {
            id
            companyName
            speakerName
            slug
            date(formatString: "DD/MM/YYYY")
            time
            timeEnd
            title
            priority
            image{
              childImageSharp{
                fluid{              
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        Background: file(relativePath: { eq: "Heilbrigdisthing-monnun_menntun-1920x1080_1.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const seo_og: MetaOG = {
    title: "Dagskrá",
    description: "Dagskrá - Heilbrigðisþing 2020",
  }

  return (
    <Layout location={location} myLocation={MyLocationEnum.Dagskra}>
      <SEO
        title="Dagskrá"
        description="Dagsrká - Heilbrigðisþing 2020"
        og={seo_og}
      />
      <Program 
        liveSpeaker={liveSpeaker}
        speakerLinks={speakerLinks}
        data={data.allSpeaker.nodes}
        backgroundImage={data.Background}
        title="Dagskrá - 27. nóvember"
        liveUrl="/beint"
        hideTimeline={false}
        smallerLogo={false}
      />
    </Layout>
  )
}